import React from 'react';
import { useSelector } from 'react-redux';
import DataGrid, { Column, Scrolling, Paging, FilterRow, Export, LoadPanel } from 'devextreme-react/data-grid';
import * as cameraInfoSelectors from 'store/cameraInfo/cameraInfoSelectors';

import styles from './Table.module.css';

function Table({
    data,
    columnsConfig,
    rowsPerPage,
    classes,
    optionChangedHandler,
    keyExpr = 'id',
    disabled,
    focusedRowKey,
    onDoubleClick,
    onContentReady,
    clickAwayListener,
    rowSelectionOnClick,
    exportData,
    hasSearch,
    onRowClick,
    cameraThrone,
    height,
    showBorders,
    showRowLines,
    showColumnLines,
    loadPanel,
}) {
    const allCamerasInfo = useSelector(cameraInfoSelectors.selectCameraInfo);

    const columns = columnsConfig.map((segment) => {
        const { caption, columns } = segment;

        let columnsConfig = columns.map((column) => {
            if (column.dataField === 'lastUpdateStatus') {
                let spanStyle = (data) => (
                    <span className={styles['dot-circle']} style={{ background: `${data.data?.lastUpdateStatus === true ? 'green' : 'red'}` }}></span>
                );
                return <Column key={column.caption} {...column} cellRender={spanStyle} />;
            } else {
                const renderAlertCell = (data) => {
                    let backgroundColor;

                    if (data.data[column.dataField] === 'INACTIVE' || data.data[column.dataField] === false) {
                        backgroundColor = '#EB5757';
                    } else if (data.data[column.dataField] === 'ACTIVE' || data.data[column.dataField] === true) {
                        backgroundColor = '#219653';
                    }
                    const spanStyle = {
                        background: backgroundColor,
                    };
                    return <span className={styles['dot-circle']} style={spanStyle}></span>;
                };

                const renderMonitoringObjectCell = (data) => {
                    return data.data[column.dataField]?.map((el, index) => {
                        if (index === 1) {
                            return (
                                <span key={el.id}>
                                    , {el.name} ({el.objectType})
                                </span>
                            );
                        } else {
                            return (
                                <span key={el.id}>
                                    {el.name} ({el.objectType})
                                </span>
                            );
                        }
                    });
                };

                if (column.dataType === 'boolean') {
                    return <Column key={column.caption} {...column} cellRender={renderAlertCell} />;
                } else if (column.dataField === 'monitoringObjects') {
                    return <Column key={column.caption} {...column} cellRender={renderMonitoringObjectCell} />;
                } else {
                    return <Column key={column.caption} {...column} />;
                }
            }
        });

        if (caption) {
            columnsConfig = (
                <Column key={caption} caption={caption}>
                    {columnsConfig}
                </Column>
            );
        }
        return columnsConfig;
    });

    const onCellPrepared = (e) => {
        if (e.rowType === 'data' && cameraThrone) {
            e.row.cells.map((el) => {
                allCamerasInfo.forEach((camera) => {
                    switch (el.column.name) {
                        case 'streamKeyFrameInterval':
                            if (el.data.id === camera.id) {
                                camera.validationMap?.streamKeyFrameIntervalStatus && (el.cellElement.style.backgroundColor = '#219653');
                                camera.validationMap?.streamKeyFrameIntervalStatus === false && (el.cellElement.style.backgroundColor = '#EB5757');
                            }
                            break;
                        case 'urlNvrStreamQualityMain':
                            if (el.data.id === camera.id) {
                                camera.validationMap?.urlNvrStreamQualityMainStatus && (el.cellElement.style.backgroundColor = '#219653');
                                camera.validationMap?.urlNvrStreamQualityMainStatus === false && (el.cellElement.style.backgroundColor = '#EB5757');
                            }
                            break;
                        case 'streamFrameRate':
                            if (el.data.id === camera.id) {
                                camera.validationMap?.streamFrameRateStatus && (el.cellElement.style.backgroundColor = '#219653');
                                camera.validationMap?.streamFrameRateStatus === false && (el.cellElement.style.backgroundColor = '#EB5757');
                            }
                            break;
                        case 'urlStreamQualityMain':
                            if (el.data.id === camera.id) {
                                camera.validationMap?.urlStreamQualityMainStatus && (el.cellElement.style.backgroundColor = '#219653');
                                camera.validationMap?.urlStreamQualityMainStatus === false && (el.cellElement.style.backgroundColor = '#EB5757');
                            }
                            break;
                        case 'urlProxyStreamQualitySub':
                            if (el.data.id === camera.id) {
                                camera.validationMap?.urlProxyStreamQualitySubStatus && (el.cellElement.style.backgroundColor = '#219653');
                                camera.validationMap?.urlProxyStreamQualitySubStatus === false && (el.cellElement.style.backgroundColor = '#EB5757');
                            }
                            break;
                        case 'audioCompressionType':
                            if (el.data.id === camera.id) {
                                camera.validationMap?.audioCompressionTypeStatus && (el.cellElement.style.backgroundColor = '#219653');
                                camera.validationMap?.audioCompressionTypeStatus === false && (el.cellElement.style.backgroundColor = '#EB5757');
                            }
                            break;
                        case 'streamCodecType':
                            if (el.data.id === camera.id) {
                                camera.validationMap?.streamCodecTypeStatus && (el.cellElement.style.backgroundColor = '#219653');
                                camera.validationMap?.streamCodecTypeStatus === false && (el.cellElement.style.backgroundColor = '#EB5757');
                            }
                            break;
                        case 'urlProxyStreamQualityMain':
                            if (el.data.id === camera.id) {
                                camera.validationMap?.urlProxyStreamQualityMainStatus && (el.cellElement.style.backgroundColor = '#219653');
                                camera.validationMap?.urlProxyStreamQualityMainStatus === false && (el.cellElement.style.backgroundColor = '#EB5757');
                            }
                            break;
                        case 'streamSourceMain':
                            if (el.data.id === camera.id) {
                                camera.validationMap?.streamSourceMain && (el.cellElement.style.backgroundColor = '#219653');
                                camera.validationMap?.streamSourceMain === false && (el.cellElement.style.backgroundColor = '#EB5757');
                            }
                            break;
                        case 'urlStreamQualityMainAudio':
                            if (el.data.id === camera.id) {
                                camera.validationMap?.urlStreamQualityMainAudio && (el.cellElement.style.backgroundColor = '#219653');
                                camera.validationMap?.urlStreamQualityMainAudio === false && (el.cellElement.style.backgroundColor = '#EB5757');
                            }
                            break;
                        case 'urlStreamQualityMainVideo':
                            if (el.data.id === camera.id) {
                                camera.validationMap?.urlStreamQualityMainVideo && (el.cellElement.style.backgroundColor = '#219653');
                                camera.validationMap?.urlStreamQualityMainVideo === false && (el.cellElement.style.backgroundColor = '#EB5757');
                            }
                            break;
                        case 'urlNvrStreamQualityMainAudio':
                            if (el.data.id === camera.id) {
                                camera.validationMap?.urlNvrStreamQualityMainAudio && (el.cellElement.style.backgroundColor = '#219653');
                                camera.validationMap?.urlNvrStreamQualityMainAudio === false && (el.cellElement.style.backgroundColor = '#EB5757');
                            }
                            break;
                        case 'urlNvrStreamQualityMainVideo':
                            if (el.data.id === camera.id) {
                                camera.validationMap?.urlNvrStreamQualityMainVideo && (el.cellElement.style.backgroundColor = '#219653');
                                camera.validationMap?.urlNvrStreamQualityMainVideo === false && (el.cellElement.style.backgroundColor = '#EB5757');
                            }
                            break;
                        case 'urlProxyStreamQualityMainAudio':
                            if (el.data.id === camera.id) {
                                camera.validationMap?.urlProxyStreamQualityMainAudio && (el.cellElement.style.backgroundColor = '#219653');
                                camera.validationMap?.urlProxyStreamQualityMainAudio === false && (el.cellElement.style.backgroundColor = '#EB5757');
                            }
                            break;
                        case 'urlProxyStreamQualityMainVideo':
                            if (el.data.id === camera.id) {
                                camera.validationMap?.urlProxyStreamQualityMainVideo && (el.cellElement.style.backgroundColor = '#219653');
                                camera.validationMap?.urlProxyStreamQualityMainVideo === false && (el.cellElement.style.backgroundColor = '#EB5757');
                            }
                            break;
                        case 'urlProxyStreamQualitySubAudio':
                            if (el.data.id === camera.id) {
                                camera.validationMap?.urlProxyStreamQualitySubAudio && (el.cellElement.style.backgroundColor = '#219653');
                                camera.validationMap?.urlProxyStreamQualitySubAudio === false && (el.cellElement.style.backgroundColor = '#EB5757');
                            }
                            break;
                        case 'urlProxyStreamQualitySubVideo':
                            if (el.data.id === camera.id) {
                                camera.validationMap?.urlProxyStreamQualitySubVideo && (el.cellElement.style.backgroundColor = '#219653');
                                camera.validationMap?.urlProxyStreamQualitySubVideo === false && (el.cellElement.style.backgroundColor = '#EB5757');
                            }
                            break;
                        case 'httpPort':
                            if (el.data.id === camera.id) {
                                camera.validationMap?.httpPortStatus && (el.cellElement.style.backgroundColor = '#219653');
                                camera.validationMap?.httpPortStatus === false && (el.cellElement.style.backgroundColor = '#EB5757');
                            }
                            break;
                        case 'rtspPort':
                            if (el.data.id === camera.id) {
                                camera.validationMap?.rtspPortStatus && (el.cellElement.style.backgroundColor = '#219653');
                                camera.validationMap?.rtspPortStatus === false && (el.cellElement.style.backgroundColor = '#EB5757');
                            }
                            break;
                        case 'onlineUsers':
                            if (el.data.id === camera.id) {
                                camera.validationMap?.onlineUsersStatus && (el.cellElement.style.backgroundColor = '#219653');
                                camera.validationMap?.onlineUsersStatus === false && (el.cellElement.style.backgroundColor = '#EB5757');
                            }
                            break;
                        default:
                            break;
                    }
                });

                return el;
            });
        }
    };

    const focusedRow = focusedRowKey ? { focusedRowKey } : null;
    const noDataText = loadPanel ? '' : 'No data';

    return (
        <DataGrid
            height={height}
            disabled={disabled}
            dataSource={data}
            keyExpr={keyExpr}
            allowColumnReordering={false}
            showColumnLines={showColumnLines}
            showRowLines={showRowLines}
            focusedRowEnabled={rowSelectionOnClick}
            allowColumnResizing={true}
            columnResizingMode="widget"
            columnAutoWidth={true}
            showBorders={showBorders}
            onOptionChanged={optionChangedHandler}
            onCellPrepared={onCellPrepared}
            onRowDblClick={onDoubleClick}
            className={classes}
            clickAwayListener={clickAwayListener}
            onRowClick={onRowClick}
            onContentReady={onContentReady}
            noDataText={noDataText}
            {...focusedRow}
        >
            <Paging defaultPageSize={rowsPerPage - 1} mode="full" showNavigtionButtons={true} />
            <Scrolling mode="standard" showScrollbar="onHover" useNative={true} />
            <LoadPanel enabled={true} visible={loadPanel} />
            {hasSearch && <FilterRow visible={true} />}
            {columns}

            {exportData && <Export enabled={true} allowExportSelectedData={true} />}
        </DataGrid>
    );
}

export default Table;
