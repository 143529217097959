import React from 'react';
import { useSelector } from 'react-redux';
import DataGrid, { Column, Scrolling, Paging, FilterRow, Export } from 'devextreme-react/data-grid';
import * as controlCentarSelector from 'store/controlCentar/controlCentarSelectors';
import WaitingCircle from 'features/controlCentar/table/waitingCircle/waitingCircle';
import { Button } from '@mui/material';
import styles from './Table.module.css';
import { statusColors } from 'pages/ControlCentar/controlCentar';

function Table({
    data,
    columnsConfig,
    rowsPerPage,
    classes,
    optionChangedHandler,
    keyExpr = 'id',
    disabled,
    focusedRowKey,
    onDoubleClick,
    onContentReady,
    clickAwayListener,
    rowSelectionOnClick,
    exportData,
    hasSearch,
    onRowClick,
    height,
    showBorders,
    showRowLines,
    showColumnLines,
    loadPanel,
    handleControlStates,
}) {
    const buttonState = useSelector(controlCentarSelector.buttonState);
    const selectedLocationRow = useSelector(controlCentarSelector.selectedLocationRow);
    const selectedCameraRow = useSelector(controlCentarSelector.selectedCameraRow);

    const columns = columnsConfig.map((segment) => {
        const { caption, columns } = segment;

        let columnsConfig = columns.map((column, index) => {
            const renderAlertCell = (data) => {
                let backgroundColor;
                let cellStatus;
                const stages = ['Alisa', 'Aiq', 'Aiq Database', 'Aiq Talk', 'MediaMtx'];

                stages.forEach((stage) => {
                    if (column.dataField === `deploymentState.stages.${stage}.stageStatus` && (data.data.deploymentState?.stages || data.status)) {
                        switch (data.data.deploymentState?.stages[stage]?.stageOutcome) {
                            case 'OK':
                                if (data.data.deploymentState.stages[stage].stageStatus === 'INACTIVE') {
                                    backgroundColor = statusColors.inactive.color;
                                } else if (data.data.deploymentState.stages[stage].stageStatus === 'ACTIVE') {
                                    backgroundColor = statusColors.active.color;
                                } else if (data.data.deploymentState.stages[stage].stageStatus === 'ERROR') {
                                    backgroundColor = statusColors.error.color;
                                } else if (data.data.deploymentState.stages[stage].stageStatus === 'PARTIAL') {
                                    backgroundColor = statusColors.partial.color;
                                } else if (data.data.deploymentState.stages[stage].stageStatus === 'WAITING') {
                                    cellStatus = <WaitingCircle />;
                                }
                                break;
                            case 'WAITING':
                                cellStatus = <WaitingCircle />;
                                break;
                            case 'ERROR':
                                backgroundColor = statusColors.error.color;
                                break;
                            default:
                        }
                    } else if (data.data[column.dataField] === 'PARTIAL') {
                        backgroundColor = statusColors.partial.color;
                    } else if (data.data[column.dataField] === 'ERROR') {
                        backgroundColor = statusColors.error.color;
                    } else if (data.data[column.dataField] === 'WAITING' || data.data[column.dataField] === 'WAITING') {
                        return <WaitingCircle />;
                    } else if (data.data[column.dataField] === 'INACTIVE' || data.data[column.dataField] === false) {
                        backgroundColor = statusColors.inactive.color;
                    } else if (data.data[column.dataField] === 'ACTIVE' || data.data[column.dataField] === true) {
                        backgroundColor = statusColors.active.color;
                    }
                });
                const spanStyle = {
                    background: backgroundColor,
                };
                if (!cellStatus) {
                    cellStatus = <span className={styles['dot-circle']} style={spanStyle}></span>;
                }

                return cellStatus;
            };
            const onDataLocation = JSON.stringify({
                id: selectedLocationRow?.id,
                aimedStatus: 'ACTIVE',
            });

            const offDataLocation = JSON.stringify({
                id: selectedLocationRow?.id,
                aimedStatus: 'INACTIVE',
            });

            const onDataCamera = JSON.stringify({
                id: selectedCameraRow?.id,
                aimedStatus: 'ACTIVE',
            });
            const offDataCamera = JSON.stringify({
                id: selectedCameraRow?.id,
                aimedStatus: 'INACTIVE',
            });
            const renderControlCell = (onData, offData, destination) => {
                return (
                    <div className={styles['controls-button-container']}>
                        <Button
                            className={styles['controls-button']}
                            disabled={buttonState}
                            onClick={() => handleControlStates(onData, destination)}
                            variant="contained"
                            color="success"
                        >
                            On
                        </Button>
                        <Button
                            className={styles['controls-button']}
                            disabled={buttonState}
                            onClick={() => handleControlStates(offData, destination)}
                            variant="contained"
                            style={{ backgroundColor: '#919191', color: 'white' }}
                        >
                            Off
                        </Button>
                    </div>
                );
            };

            const renderMonitoringObjectCell = (data) => {
                return data.data['monitoringObjects']?.map((el, index) => {
                    if (index === 1) {
                        return (
                            <span key={el.id}>
                                , {el.name} ({el.objectType})
                            </span>
                        );
                    } else {
                        return (
                            <span key={el.id}>
                                {el.name} ({el.objectType})
                            </span>
                        );
                    }
                });
            };

            if (column.dataType === 'boolean') {
                return <Column key={`${column.caption}${index}`} {...column} cellRender={renderAlertCell} />;
            } else if (column.dataField === 'controlsCameraButton') {
                return (
                    <Column
                        key={`${column.caption}${index}`}
                        {...column}
                        cellRender={() => renderControlCell(onDataCamera, offDataCamera, '/ccenter/camera/action')}
                    />
                );
            } else if (column.dataField === 'controlsLocationButton') {
                return (
                    <Column
                        key={`${column.caption}${index}`}
                        {...column}
                        cellRender={() => renderControlCell(onDataLocation, offDataLocation, '/ccenter/location/action')}
                    />
                );
            } else if (column.dataField === 'cameraName') {
                return <Column key={`${column.caption}${index}`} {...column} cellRender={renderMonitoringObjectCell} />;
            } else {
                return <Column key={`${column.caption}${index}`} {...column} />;
            }
        });

        if (caption) {
            columnsConfig = (
                <Column key={caption} caption={caption}>
                    {columnsConfig}
                </Column>
            );
        }
        return columnsConfig;
    });

    const focusedRow = focusedRowKey ? { focusedRowKey } : null;
    return (
        <DataGrid
            height={height}
            disabled={disabled}
            dataSource={data}
            keyExpr={keyExpr}
            allowColumnReordering={false}
            showColumnLines={showColumnLines}
            showRowLines={showRowLines}
            focusedRowEnabled={rowSelectionOnClick}
            allowColumnResizing={true}
            columnResizingMode="widget"
            columnAutoWidth={true}
            showBorders={showBorders}
            loadPanel={{ enabled: loadPanel }}
            onOptionChanged={optionChangedHandler}
            onRowDblClick={onDoubleClick}
            className={classes}
            clickAwayListener={clickAwayListener}
            onRowClick={onRowClick}
            onContentReady={onContentReady}
            {...focusedRow}
        >
            <Paging defaultPageSize={rowsPerPage - 1} mode="full" showNavigtionButtons={true} />
            {hasSearch && <FilterRow visible={true} />}
            {columns}

            {exportData && <Export enabled={true} allowExportSelectedData={true} />}
        </DataGrid>
    );
}

export default Table;
