import { all, call, put, takeEvery } from 'redux-saga/effects';
import notificationsHandler from 'utils/notificationsHandler';
import { controlCentarActions } from './controlCentarSlice';
import { axiosLasta } from 'config';

export function* fetchModalTableValues({ payload: id }) {
    try {
        const response = yield axiosLasta(`/monitoring/elements/location/${id}/camera`);
        const { data } = response;
        data.map((el) => {
            if (el.monitoringObjects) {
                el.monitoringObjects.map((elem) => {
                    el.cameraName = `${elem.name.trim()} (${elem.objectType.trim()})`;
                    return el;
                });
            }
            return el;
        });

        data.monitoringObjects &&
            data.monitoringObjects.map((el) => {
                el.title = `${el.name.trim()} (${el.objectType.trim()})`;
                return el;
            });
        data.sort(function (a, b) {
            var textA = a?.name?.toUpperCase();
            var textB = b?.name?.toUpperCase();
            return textA < textB ? -1 : textA > textB ? 1 : 0;
        });

        if (response.status === 200) {
            yield put(controlCentarActions.setCamerasValues(data));
        }
    } catch (err) {
        yield notificationsHandler({ title: `Error fetching camera information` });
    }
}

export function* onfetchModalTableValues() {
    yield takeEvery(controlCentarActions.fetchModalTableValues.type, fetchModalTableValues);
}

export function* controlCentarSaga() {
    yield all([call(onfetchModalTableValues)]);
}
