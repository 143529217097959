import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    dropdownValues: {
        family: {
            urlPath: 'monitoring/monitoring-element-family/all',
        },
        manufacturer: {
            urlPath: 'monitoring/manufacturer/all',
        },
        location: {
            urlPath: 'location/all',
        },
        mediaProxyUUID: {
            urlPath: 'proxy/all',
        },
        streamSource: {
            urlPath: '/proxy/streaming/options',
        },
        status: {
            dataSource: [
                { status: 'ACTIVE', id: 'ACTIVE' },
                { status: 'INACTIVE', id: 'INACTIVE' },
            ],
        },
        monitoringObjects: {},
    },
    isFormInEditingMode: false,
    familyIsCamera: false,
    activeMonitoringElementFamilyId: 'all',
    removeModalState: false,
    monitoringElement: null,
    isNVRSelected: false,
};

const monitoringElementList = createSlice({
    name: 'monitoringElementList',
    initialState,
    reducers: {
        setIsFormInEditingMode(state, { payload: editingMode }) {
            state.isFormInEditingMode = editingMode;
        },
        toggelEditingMode(state) {
            state.isFormInEditingMode = !state.isFormInEditingMode;
        },
        setDropDownValues(state, { payload }) {
            if (payload.fieldName === 'streamSource') {
                const objStreamSourceMain = [];
                for (const key of payload.dataSource.mainStream.streamSource) {
                    let object = {};
                    object['name'] = key;
                    object['id'] = key;
                    objStreamSourceMain.push(object);
                }

                const objstreamProcessingMain = [];
                for (const key of payload.dataSource.mainStream.streamProcessing) {
                    let object = {};
                    object['name'] = key;
                    object['id'] = key;
                    objstreamProcessingMain.push(object);
                }

                const objStreamSourceSub = [];
                for (const key of payload.dataSource.subStream.streamSource) {
                    let object = {};
                    object['name'] = key;
                    object['id'] = key;
                    objStreamSourceSub.push(object);
                }
                const objstreamProcessingSub = [];
                for (const key of payload.dataSource.subStream.streamProcessing) {
                    let object = {};
                    object['name'] = key;
                    object['id'] = key;
                    objstreamProcessingSub.push(object);
                }

                const objCameraType = [];
                for (const key of payload.dataSource.cameraTypes) {
                    let object = {};
                    object['name'] = key;
                    object['id'] = key;
                    objCameraType.push(object);
                }
                state.dropdownValues = {
                    ...state.dropdownValues,
                    sourceMain: { dataSource: objStreamSourceMain },
                    streamProcessingMain: { dataSource: objstreamProcessingMain },
                    sourceSub: { dataSource: objStreamSourceSub },
                    streamProcessingSub: { dataSource: objstreamProcessingSub },
                    elementType: { dataSource: objCameraType },
                };
            } else if (payload.fieldName === 'monitoringObjects') {
                const dataObject = { ...payload.dataSource };

                let namePropertyChanged = [];

                for (const key in dataObject) {
                    dataObject[key].title = `${dataObject[key].name.trim()} (${dataObject[key].objectType.trim()})`;
                    namePropertyChanged.push(dataObject[key]);
                }

                state.dropdownValues = {
                    ...state.dropdownValues,
                    [payload.fieldName]: { ...state.dropdownValues[payload.fieldName], dataSource: namePropertyChanged },
                };
            } else {
                payload.dataSource.sort(function (a, b) {
                    var textA = a?.yardName?.toUpperCase();
                    var textB = b?.yardName?.toUpperCase();
                    return textA < textB ? -1 : textA > textB ? 1 : 0;
                });
                state.dropdownValues = {
                    ...state.dropdownValues,
                    [payload.fieldName]: { ...state.dropdownValues[payload.fieldName], dataSource: payload.dataSource },
                };
            }
        },
        fetchDropDownValues() {},
        fetchLocationNVR() {},
        removeMonitoringObjectFromSelectedElement(state) {
            const duplicatedState = { ...state.dropdownValues };
            duplicatedState.monitoringObjects.dataSource = null;
            state.dropdownValues = { ...duplicatedState };
        },
        removeNVRFromSelectedElement(state) {
            const duplicatedState = { ...state.dropdownValues };
            if (duplicatedState.nvrId) {
                duplicatedState.nvrId.dataSource = null;
            }
            state.dropdownValues = { ...duplicatedState };
        },
        setFamilyIsCamera(state, { payload }) {
            state.familyIsCamera = payload;
        },
        deleteMonitoringObject() {},
        setActiveMonitoringElementFamilyId(state, { payload }) {
            state.activeMonitoringElementFamilyId = payload;
        },
        setRemoveModalState(state, { payload }) {
            state.removeModalState = payload;
        },
        setSelectedMonitoringElement(state, { payload }) {
            state.monitoringElement = payload;
        },
        getSingleMonitoringElement() {},
        setSingleMonitoringElement(state, { payload }) {
            state.monitoringElement = payload;
        },
        setIsNVRSelected(state, { payload }) {
            state.isNVRSelected = payload;
        },
    },
});

export const monitoringElementActions = monitoringElementList.actions;
export const monitoringElementReducer = monitoringElementList.reducer;
