import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { tableControlActions } from './tableControlSlice';
import notificationsHandler from 'utils/notificationsHandler';
import { selectTableConfig } from './tableControlSelectors';

import { axiosLasta } from 'config';

function* fetchTableData({ payload: { tableId, urlPath, pageNum = 0, size = 20, append = false, fetchTableConfig = true } }) {
    try {
        const response = yield axiosLasta.get(urlPath);
        const { data } = response;

        data.sort(function (a, b) {
            var textA = a?.location?.yardName?.toUpperCase();
            var textB = b?.location?.yardName?.toUpperCase();
            return textA < textB ? -1 : textA > textB ? 1 : 0;
        });
        data.map((el) => {
            el.monitoringObjects &&
                el.monitoringObjects.map((el) => {
                    el.title = `${el.name.trim()} (${el.objectType.trim()})`;
                    return el;
                });

            return el;
        });

        yield put(
            tableControlActions.fetchTableDataSuccess({
                data,
                tableId,
                pageNum,
                append,
            })
        );

        if (!fetchTableConfig) return;
        const tableConfig = yield select(selectTableConfig);
        if (!tableConfig) {
            yield put(tableControlActions.fetchTableConfigStart(tableId));
        }
    } catch (err) {
        yield put(tableControlActions.fetchTableDataFailed({ error: err.message, tableId }));
        yield call(notificationsHandler, {
            err,
            title: 'Error fetching table data',
        });
    }
}

function* fetchTableConfig() {
    try {
        const response = yield axiosLasta.get(`/transactions/trucks/table/columns?type=Status`);
        const { data } = response;

        yield put(tableControlActions.fetchTableConfigSuccess(data));
    } catch (err) {
        yield call(notificationsHandler, {
            err,
            action: tableControlActions.fetchTableConfigFailed,
            title: 'Error fetching table configuration',
        });
    }
}

function* onFetchTableConfigStart() {
    yield takeLatest(tableControlActions.fetchTableConfigStart.type, fetchTableConfig);
}

function* onFetchTableDataStart() {
    yield takeEvery(tableControlActions.fetchTableDataStart.type, fetchTableData);
}
export function* tableControlSaga() {
    yield all([call(onFetchTableDataStart), call(onFetchTableConfigStart)]);
}
